var googleMap = {

	elms: {
		villageMap: $('#village-google-map')
	},

	loadScript: function() {
		var apiKey = googleMap.elms.villageMap.attr('data-api');

		var script = document.createElement('script');
		script.type = "text/javascript";
		script.src = 'https://maps.googleapis.com/maps/api/js?key=' + apiKey +
		      '&callback=setUP';
		  document.body.appendChild(script);
	},

	setUp: function() {
		var google = window.google || {};

		var lat = parseFloat(googleMap.elms.villageMap.attr('data-lat'));
		var lng = parseFloat(googleMap.elms.villageMap.attr('data-lng'));
		var zoom = parseInt(googleMap.elms.villageMap.attr('data-zoom'));
		var iconImg = googleMap.elms.villageMap.attr('data-marker');
		var myLatLng = new google.maps.LatLng( lat, lng );

		var mapOptions = {
			center: { lat: lat, lng: lng},
			zoom: zoom
		};

		var map = new google.maps.Map(document.getElementById('village-google-map'),
		mapOptions);

		var marker = new google.maps.Marker({
			position: myLatLng,
			map: map,
			icon: iconImg
		});
	},

	init: function() {

		if ( googleMap.elms.villageMap.length > 0 ) {
			window.setUP = googleMap.setUp;

			googleMap.loadScript();
		}
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = googleMap;
}
