var backToTop = {

	elWrap: $('.back-to-top-wrap'),

	scrollToTop: function() {
		$('html, body').animate({ scrollTop: 0 }, 'slow');
		return false;
	},

	eventListeners: function() {
		backToTop.elWrap.on('click', function() {
			backToTop.scrollToTop();
		});
	},

	init: function() {
		backToTop.eventListeners();
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = backToTop;
}
