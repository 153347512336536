var desktopNav = {

	triggers: $('.nav-main > ul > li.nav-navItem'),
	navTray: $('.nav-main'),
	openTimeout: undefined,
	closeTimeout: undefined,
	closeTrayAnimTimeout: undefined,
	settings: {
		openTimeoutDelay: 50,
		closeTimeoutDelay: 50,
		resetCloseTimeoutDelay: 50
	},

	showSubNav: function(el) {
		// console.log('showSubNav');

		// hide all other subnavs
		desktopNav.triggers.each(function(){
			desktopNav.triggers.removeClass('active');
		});

		$(el).addClass('active');
	},

	openNavTray: function() {
		clearTimeout(desktopNav.closeTimeout);

		desktopNav.navTray.addClass('opened');
	},

	closeNavTray: function() {
		// console.log('closeNavTray');

		desktopNav.navTray.addClass('closed');

	

		desktopNav.closeTrayAnimTimeout = setTimeout(function(){
			desktopNav.triggers.each(function(){
				desktopNav.triggers.removeClass('active');
				desktopNav.navTray.removeClass('closed');
				desktopNav.navTray.removeClass('opened');
			});
		}, desktopNav.settings.resetCloseTimeoutDelay);
	},

	init: function() {
		// console.log('desktopNav.init()')

		desktopNav.navTray.addClass('opened');

		desktopNav.triggers.on('mouseenter', function(e){


			// // hovered anywhere over the current item (eg. the subnav tray)
			if (desktopNav.navTray.hasClass('opened')){
				clearTimeout(desktopNav.closeTimeout);
				clearTimeout(desktopNav.resetCloseTimeoutDelay);
				
			}

			// hovered elsewhere rather than a main nav link (eg. the subnav tray)
			if (!$(e.target).hasClass('nav-parent')){
				return;
			}

			desktopNav.showSubNav(e.currentTarget);

			desktopNav.openTimeout = setTimeout(function(){
				desktopNav.openNavTray();
			}, desktopNav.settings.openTimeoutDelay)

		})

		desktopNav.triggers.on('mouseleave', function(e){
			// console.log('mouseleave');

			//clear a possible timeout
			clearTimeout(desktopNav.openTimeout);

			desktopNav.closeTimeout = setTimeout(function(){
				 desktopNav.closeNavTray();
			}, desktopNav.settings.closeTimeoutDelay)
		});
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = desktopNav;
}
