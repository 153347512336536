var accordionTabs = {

	elms: {
		accordionTabs: $('.accordion-tabs'),
		accordionTabWrap: $('.accordion-tab-wrap'),
		tabTrigger: $('.tab-trigger'),
		accordionTrigger: $('.accordion-tab-trigger'),
		trigger: $('[data-trigger]'),
		accordionTabContent: $('.accordion-tab-content')
	},

	toggleAccordionContent: function( $accordionTabContent ) {
		// Slide up all the accordionTabContents within only the accordionTabWraps that are not active
		accordionTabs.elms.accordionTabContent.not('.active').slideUp(400);

		// Slide down the triggered content
		$accordionTabContent.slideDown(400);
	},

	toggleTabContent: function( $accordionTabContent ) {
		accordionTabs.elms.accordionTabContent.hide();
		$accordionTabContent.show();
	},

	eventListeners: function() {
		// Desktop
		accordionTabs.elms.trigger.on('click', function( e ) {
			e.preventDefault();
			// Find the correct accordionTabContent dependent on the trigger clicked
			var $trigger = $(e.target).closest('[data-trigger]');
			var triggerText = $trigger.attr('data-trigger');
			var $triggers = accordionTabs.elms.trigger.filter('[data-trigger=' + triggerText + ']');
			var $accordionTabContent = accordionTabs.elms.accordionTabContent.filter('[data-tab="' + triggerText + '"]');

			// Change the active classes
			accordionTabs.elms.trigger.removeClass('active');
			$triggers.addClass('active');

			accordionTabs.elms.accordionTabContent.removeClass('active');
			$accordionTabContent.addClass('active');

			// If tablet/mobile, call the appropriate and pass in the above element
			if ( $(window).width() < tabletWide ) {
				accordionTabs.toggleAccordionContent( $accordionTabContent );
			} else {
				accordionTabs.toggleTabContent( $accordionTabContent );
			}
		});

		if(window.location.hash) {
			var $content = $(window.location.hash);
			if($content.length > 0) {
				$content.parent().children('[data-trigger]').trigger('click');
			}
		}

		$( window ).on( 'hashchange', function( e ) {
			e.preventDefault();

			var $content = $(window.location.hash);
			if($content.length > 0) {
				$content.parent().children('[data-trigger]').trigger('click');
				nav.openClose.closeNavResize();

				var target = $content;

				setTimeout(function(){
					$('html,body').animate({
						scrollTop: target.offset().top - $(".header-logo").height()
					}, 1000);
				}, 1000);
			}
		});
	},

	init: function() {
		// If element exsists
		if ( accordionTabs.elms.accordionTabs.length > 0 ) {
			// Hide all tabs/accordions apart from active ones
			accordionTabs.elms.accordionTabContent.not('.active').hide();

			// Listen to the click events
			accordionTabs.eventListeners();
		}
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = accordionTabs;
}
