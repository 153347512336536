var modal = {


	// ----- VARS ----- //
	elms: {
		close: undefined,
		content: undefined,
		modal: undefined,
		triggers: $( '[data-toggle="modal"]:not(.product):not(.latest-trends-product)' ),
	},


	// ----- CONSTANTS ----- //
	EVENT_TRANSITION: 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',

	
	// ----- FUNCTIONS ----- //
	init: function() {
		//console.log( 'modal: init' );

		modal.elms.triggers.on( 'click', modal.elms.triggers, modal.onClick );

	},

	show: function( selector, title ) {
		//console.log( 'modal: show', selector );

		modal.elms.modal = $( selector );
		modal.elms.close = modal.elms.modal.find( '[data-dismiss="modal"]' );
		modal.elms.content = modal.elms.modal.find( '.modal-content' );

		if (title)
			modal.elms.content.find('.modal-title').text(title);

		modal.elms.modal.on( 'click', modal.onClose );

		// Update Share Content if Applicable
		if ( selector.match( /share/i ) )
			modal.updateShare();

		// Add to Display
		modal.elms.modal.css( 'display', 'flex' );
		modal.elms.modal[0].offsetWidth;

		// Transition In
		modal.elms.modal.addClass( 'in' );

	},

	hide: function() {
		//console.log( 'modal: hide' );

		// Remove Listeners
		modal.elms.modal.off( 'click', modal.onClose );

		// Hide Modal
		modal.elms.modal.removeClass( 'in' );

		setTimeout(function(){
			modal.elms.modal.removeAttr( 'style' );
			// Unassign Variables and Remove Listeners
			modal.elms.modal.off( modal.EVENT_TRANSITION, modal.onHideComplete );

			modal.elms.close = undefined;
			modal.elms.content = undefined;
			modal.elms.modal = undefined;
		}, 500);

	},

	updateShare: function() {
		//console.log( 'modal: updateShare' );

		modal.elms.modal.attr( 'data-share-url', window.location.href );
		var $shareItems = modal.elms.modal.find( '.share-item > a' );

		socialLinks.create( $shareItems );

	},


	// ----- EVENT LISTENERS ----- //
	onClick: function( e ) {
		//console.log( 'modal: onClick' );
		e.preventDefault();

		var $target = $( e.currentTarget );
		var modalId = $target.data( 'target' );
		var modalTitle = $target.data( 'modal-title' );
		var modalGallerySlide = $target.data( 'gallery-index' );

		if (modalGallerySlide) {
			gallery.setupModal(modalGallerySlide);
			return;
		}

		modal.show( modalId, modalTitle );

	},

	onClose: function( e ) {
		//console.log( 'modal: onClose' );

		var isContent = $.contains( modal.elms.content[0], e.target );
		var isClose = $.contains( modal.elms.close[0], e.target ) || modal.elms.close[0] === e.target;

		if ( isClose || !isContent )
			modal.hide();

	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = modal;
}
