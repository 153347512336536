var text = {

	linkOut: {
		elem: $('.link-out'),
		wrapperSpan: $('.link-out > span'),

		wrap: function() {
			text.linkOut.elem.each(function(index, element) {
			    var link = $(element), word_array, last_word, first_part;

			    word_array = link.html().split(/\s+/); // split on spaces
			    last_word = word_array.pop();          // pop the last word
			    first_part = word_array.join(' ');     // rejoin the first words together

			    link.html([first_part, ' <span>', last_word, '&nbsp;<b></b></span>'].join(''));
			});
		}
	},

	barArrow: {
		elem: $('.alert-bar-single'),

		wrap: function() {
			text.barArrow.elem.each(function(index, element) {
			    var link = $(element), word_array, last_word, first_part;

			    word_array = link.text().split(/\s+/); // split on spaces
			    last_word = word_array.pop();          // pop the last word
			    first_part = word_array.join(' ');     // rejoin the first words together

					if($('.layout-reversed').length){

						var linkText = link.text().split(' '),
								firstWord = linkText[0],
								restOf = linkText.shift(),
								joinedRest = linkText.join(' ');
						link.html(['<span>', joinedRest, '</span>', '<span class="webfont-arrow-long">', firstWord, '</span> '].join(''));
					} else {
						link.html([first_part, ' <span class="webfont-arrow-long" dir="rtl">', last_word, '</span>'].join(''));
					}
			});
		}
	},

	init: function() {
		text.linkOut.wrap();
		text.barArrow.wrap();

		//break inspiration title in the home page in two lines
	 	var inspirationTitle = $('.home-page-get-inspired .section-title-big');
	 	if (inspirationTitle.html()){
	 		inspirationTitle.html( inspirationTitle.html().replace(' ','<br/>') );
	 	}

	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = text;
}
