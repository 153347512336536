//  ***********************
//  $$ Document ready
//  ***********************
$(function() {

	window.windowWidth = window.innerWidth;
	window.windowHeight = window.innerHeight;

	window.isiPhone = navigator.userAgent.toLowerCase().indexOf('iphone');
	window.isiPad = navigator.userAgent.toLowerCase().indexOf('ipad');
	window.isiPod = navigator.userAgent.toLowerCase().indexOf('ipod');
	window.isAndroid = function(){ return /Android/i.test(navigator.userAgent); }
	window.getAndroidVersion = function(ua) {
		ua = (ua || navigator.userAgent).toLowerCase();
		var match = ua.match(/android\s([0-9\.]*)/);
		return match ? match[1] : false;
	};

	window.isIOS = false;
	if ( window.isiPhone > 0 || window.isiPad > 0 || window.isiPod > 0 ) {
		window.isIOS = true;
		$('html').addClass('iOS');
	}

	window.tablet = 640;
	window.tabletWide = 800;
	window.desktop = 1024;

	window.isTouch = false;
	if (('ontouchstart' in document.documentElement) || Modernizr.touchevents)
		window.isTouch = true;
	// isTouch = true;

	if (isTouch || Modernizr.touchevents) {
		$('html').addClass('touch');
	}

	// Detect IE11 and Add Modernizer-like Class to HTML Element
	if ( $.browser.msie && $.browser.version <= 11 )
		$( 'html' ).addClass( 'lt-ie12' );

	/// Detect Desktop/Mobile and Add Modernizer-like Class to HTML Element
	if ( $.browser.desktop )
		$( 'html' ).addClass( 'desktop' );
	else if ( $.browser.mobile )
		$( 'html' ).addClass( 'mobile' );

	if (window.isAndroid())
		$( 'html' ).addClass( 'android' )

	if (parseInt(getAndroidVersion(), 10) == 6)
		$( 'html' ).addClass( 'android6' )


	window.rtl = false;
	if ($('.layout-reversed').length)
		window.rtl = true;

	// Add functions here

	// accessibility.js
	accessibility.init();

	// nav.js
	nav.init();

	// desktop-nav.js
	if (!isTouch && windowWidth >= desktop) {
		desktopNav.init();
	}

	// dropdowns.js
	dd.init();

	// slider.js
	slider.init();

	// forms.js
	form.init();

	// ellipsis-new.js
	if($('body').hasClass('page-no-ellipsis') === false){
	ellipsisNew.init();
	}

	// search.js
	search.init();

	// text.js
	text.init();

	// backToTop.js
	backToTop.init();

	// accordion.js
	accordion.init();

	// accordionTabs.js
	accordionTabs.init();

	// customScrollbar.js
	customScrollbar.init();

	// cookies.js
	cookies.init();

 	// videos.js
 	videos.init();

 	// modal.js
 	modal.init();

	// widgetCarousel.js
 	widgetCarousel.init();

 	// accordion-form.js
 	accordionForm.init();

 	// googleMap.js
	googleMap.init();

	// validation.js
	validation.init();

	// dateTimePicker.js
	dateTimePicker.init();

	if( $('.open-wen-filled').length ){
		accordion_progression.init();
	}

	supScripts.init();

	// make-a-booking.js
	booking.init();
});
