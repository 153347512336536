var accordion = {

	elms: {
		accordionWrap: $('.accordion-wrap'),
		accordionTrigger: $('.accordion-trigger'),
		accordionContent: $('.accordion-content')
	},

	toggleContent: function( $target ) {

		var $accordionContent = $target.closest( accordion.elms.accordionWrap ).find( accordion.elms.accordionContent );
		var $icon = $target.parent('.accordion-trigger').find('.view-more');

		$accordionContent.clearQueue().stop().slideToggle( 400, function() {
			if ( $accordionContent.is(':visible') ) {
				$icon.addClass('active');
			} else {
				$icon.removeClass('active');
			}
		});
	},

	checkIfMobile: function( $target ) {
		if ( accordion.elms.accordionWrap.hasClass('accordion-on-mobile') ) {
			if ( $(window).width() < 800 ) {
				accordion.toggleContent( $target );
				$target.closest('.accordion-simple').find('.view-more').toggleClass('active');
			}
		} else {
			accordion.toggleContent( $target );
			$target.closest('.accordion-simple').find('.view-more').toggleClass('active');
		}
	},

	eventListeners: function() {
		accordion.elms.accordionTrigger.on('click', function( e ) {
			e.preventDefault();
			accordion.checkIfMobile( $(e.target) );
		});
	},

	init: function() {
		// If element exsists
		if ( accordion.elms.accordionWrap.length > 0 ) {
			accordion.eventListeners();
		}
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = accordion;
}
