var videos = {

	$fullVideo: $('.full-video'),
	$videoCover: $('.video-cover'),
	$brightCoveClient: $('[data-video-client="brightcove"]'),
	moving: false,

	onStateChange: function(event) {
		var _this = $(event.target.a);
		if (_this.closest('.slider-hero-extended').length) {
			var _event = event.data;
			var contentSlider = $('.slider-hero-content');
			if (_event === 1) {
				contentSlider.addClass('slider-video-playing');
			} else if (_event === 0 || _event === 2) {
				contentSlider.removeClass('slider-video-playing');
			}
		}
	},

	pauseOther: function() {
		var activeYoutube = $('.youtube-video.active');
		if (activeYoutube.length > 0) {
			activeYoutube.each(function(){
				var thisYoutube = $(this).closest('.full-video').find('iframe').attr('id');
				players[thisYoutube].stopVideo();
			});
		}
		var videoId = $('.vjs-playing').find('video').attr('id');
		if (videoId) {
			console.log('videoId', videoId);
			setTimeout(function(){
				videojs(videoId).pause();
				videojs(videoId).currentTime(0);
			},50);
		}
	},

	playVideo: function($targetVideoCover) {
		console.log('playVideo $targetVideoCover:: ' + $targetVideoCover);
		if (!videos.moving) {
			videos.pauseOther();
			var videoClient = $targetVideoCover.attr('data-video-client');
			$('.hidden-cover').removeClass('hidden-cover').fadeIn();
			$targetVideoCover.addClass('hidden-cover').fadeOut();

			if (videoClient === 'brightcove') {
				var thisBrighcove = $targetVideoCover.closest('.full-video').find('.video-brightcove');
				var videoId = thisBrighcove.find('video').attr('id');
				console.log('playVideo line 1 thisBrighcove::' + thisBrighcove);
				console.log('playVideo line 1 videoId::' + videoId);
				if(videoId==undefined){
					return;
				}
				videojs(videoId).play();
				console.log('playVideo .play()')
				if ($targetVideoCover.closest('.slider-hero-extended').length) {
					var contentSlider = $('.slider-hero-content');
					$targetVideoCover.closest('.full-video').find('video').on('ended pause', function(){
						contentSlider.removeClass('slider-video-playing');
					});
					console.log('playVideo line 2 ')
					$targetVideoCover.closest('.full-video').find('video').on('play', function(){
						contentSlider.addClass('slider-video-playing');
					});
					console.log('playVideo line 3 ')
				}
			} else if (videoClient === 'youtube') {
				$targetVideoCover.closest('.full-video').find('.youtube-video').addClass('active');
				var videoId = $targetVideoCover.attr('data-video-trigger');

				if (isIOS === false) {
					players[videoId].playVideo();
				}
			} else {
				return;
			}
			videos.moving = false;
			return;
		}
	},

	eventListeners: function() {
		videos.$videoCover.on('mousemove', function(){
			videos.moving = true;
		});
		videos.$videoCover.on('mousedown', function(){
			videos.moving = false;
		});
		videos.$videoCover.on('mouseup', function(e) {
			videos.playVideo($(e.target).closest('.video-cover'));

			if (isTouch && $(e.target).closest('.slider-sync-simple').length) {
				slider.syncComplex.addClass('move-down');
				slider.syncComplex.slick('slickPause');
				setTimeout(function(){
					slider.syncComplex.slick('slickPlay');
				},8000);
			}
		});
	},

	init: function() {
		if (videos.$fullVideo.length > 0) {
			videos.eventListeners();
		}
	}
};

window.videos = videos;

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = videos;
}
