var dateTimePicker = {

	elms: {
		datePicker: $('.datepicker:not(#dateOfEvent)'),
		timePicker: $('.timepicker .timepicker-autoinit'),
		currentDate: $('.currentdate:not(#dateOfEvent)')
	},

	addDatePicker: function() {
		if ( dateTimePicker.elms.currentDate.length) {
			dateTimePicker.elms.currentDate.datepicker({
				dateFormat: 'dd-mm-yy',
                minDate: 0,
                autoclose:true
			});
			dateTimePicker.elms.datePicker.datepicker({
                dateFormat: 'dd-mm-yy',
                autoclose: true
			});
		} else {
			dateTimePicker.elms.datePicker.datepicker({
                dateFormat: 'dd-mm-yy',
                autoclose: true
			});
		}
	},

	addTimePicker: function() {
		// https://github.com/jonthornton/jquery-timepicker
		dateTimePicker.elms.timePicker.timepicker({
			timeFormat: 'H:i',
			disableTextInput: true, // this does not prevent mobile keybord from displaying
			// disableTouchKeyboard: true,
			useSelect: Modernizr.touch,
			// minTime: '6:30:00',
			// maxTime: '22:30:00',
			step: 15
			// disableTimeRanges: [['3:00am', '4:30am'], ['5:00pm', '8:00pm']]

		});
		// dateTimePicker.elms.timePicker.timepicker('setTime', new Date());
	},

	init: function() {
		if ( dateTimePicker.elms.datePicker.length > 0 ) {
			dateTimePicker.addDatePicker();
		}
		if ( dateTimePicker.elms.timePicker.length > 0 ) {
			dateTimePicker.addTimePicker();
		}
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = dateTimePicker;
}
