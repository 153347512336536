var cookies = {
	legal: 'cookies-legal',
	legalContainer: $('.cookies-container'),

	create: function(name,value,days) {
		var cookieExpires;
		if (days) {
			var date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
			cookieExpires = '; expires='+date.toGMTString();
		} else {
			cookieExpires = '';
		}
		document.cookie = name+'='+value+cookieExpires+'; path=/';
	},

	read: function(name) {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for (var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1,c.length);
			}
			if (c.indexOf(nameEQ) === 0) {
				return c.substring(nameEQ.length,c.length);
			}
		}
		return null;
	},

	legalClose: function() {
		var close = $('.cookies-close');

		close.click(function(event) {
			event.preventDefault();
			cookies.create('cookies-legal', '', 90);
			cookies.legalContainer.fadeOut();
		});
	},

	introLegal: function() {
		var exists = document.cookie.indexOf('cookies-legal');
		if (exists === -1) {
			cookies.legalContainer.show();
			cookies.legalClose();
		}
	},

	init: function() {
		cookies.introLegal();
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = cookies;
}
