var supScripts = {

	headings: $('.module-title, .module-free-text-header, .module-text-header'),

	regexReplace: function() {
		var $headings = supScripts.headings;

		$headings.each( function() {
			var str = $(this).html();
			var regex = /([^©®]*)([©®]+)([^©®]*)/g;

			if ( regex.test(str) ) {
				var newStr = str.replace( regex, '$1<sup>$2</sup>$3');
				$(this).html(newStr);
			}
		});
	},

	init: function() {
		supScripts.regexReplace();
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = supScripts;
}
