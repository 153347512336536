var customScrollbar = {

	el: $('.custom-scroll'),
	elWrap: $('.custom-scroll-wrap'),

	scrollBar: function( $el, theme ) {
		$el.mCustomScrollbar({
			axis:'y',
			theme: theme
		});
	},

	init: function() {
		if (customScrollbar.el.length > 0) {
			customScrollbar.scrollBar( customScrollbar.el, customScrollbar.elWrap.attr('data-scroll-theme') );
		}
		// For dropkick select, we can only give a class to the wrapper and then have to target the correct el
		if (customScrollbar.elWrap.length > 0) {
			customScrollbar.scrollBar( customScrollbar.elWrap.find('.dk-select-options'), customScrollbar.elWrap.attr('data-scroll-theme') );

		}
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = customScrollbar;
}
