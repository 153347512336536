var rtlSet;
if ($('body.layout-reversed').length) {
	rtlSet = true;
} else {
	rtlSet = false;
}
var slider = {
	single: $('.slider-single:not(.widget-carousel-new)'),
	productCarousel: $('.product-carousel'),
	heroSlider: $('.slider-hero'),
	barSlider: $('.slider-cta'),
	multiItem: $('.slider-manual.slider-full'),
	multiItem4: $('.slider-manual.slider-fullx4'),
	multiCentredItem: $('.slider-manual.slider-centred'),
	inputField: $('.slider-single').find('input[type="text"], input[type="email"]'),

	// New sliders
	syncSimple: $('.slider-sync-simple'),
	syncComplex: $('.slider-sync-complex'),

	videosInSliders: function(nextSlide) {
		var $nextSlideEl = slider.syncSimple.find(('[data-slick-index="'+nextSlide+'"]'));
		var $nextSlideVideo = $nextSlideEl.find('.video-cover');

		if ($nextSlideVideo.length) {
			videos.playVideo($nextSlideVideo);
		} else {
			videos.pauseOther();
		}
	},

	moveContentDown: function(nextSlide) {
		var $nextComplexSlideEl = slider.syncComplex.find(('[data-slick-index="'+nextSlide+'"]'));

		if (isTouch) {
			slider.syncComplex.removeClass('move-down');
			videos.pauseOther();
		} else {
			if ($nextComplexSlideEl.find('.slide-content-header.move-down').length) {
				slider.syncComplex.addClass('move-down');
			} else {
				slider.syncComplex.removeClass('move-down');
			}
		}
	},

	handleMobile: function() {
		// get content to it's original position
		slider.syncComplex.removeClass('move-down');
		// Pause video
		videos.pauseOther();
		// Show back cover once slide animation has finished
		setTimeout(function(){
			slider.syncSimple.find('.hidden-cover').removeClass('hidden-cover').fadeIn();
		},800);
	},

	setupSync: function() {
		slider.syncComplex.on('init', function(){
			$('.slider-hero-content').addClass('hero-home-loaded');
		});
		slider.syncSimple.slick({
			useTransform: true,
			speed: 800,
			mobileFirst: true,
			accessibility: true,
			arrows: false,
			// autoplaySpeed: 6000,
			dots: false,
			asNavFor: '.slider-sync-complex',
			// adaptiveHeight: false,
			rtl: window.rtl,
			responsive: [
				{
					breakpoint: 660,
					settings: {
						speed: 500,
					}
				},{
					breakpoint: 360,
					settings: {
						speed: 400,
					}
				}
			]
		});
		slider.syncComplex.on('init', function() {
			setTimeout(function(){
				slider.checkScrollPos();
			},50);
		});
		slider.syncComplex.on('beforeChange', function() {
			slider.checkScrollPos();
		});
		slider.syncComplex.slick({
			useTransform: true,
			speed: 800,
			mobileFirst: true,
			accessibility: true,
			arrows: true,
			prevArrow: '<a href="#" class="slick-prev slick-arrow"></a>',
			nextArrow: '<a href="#" class="slick-next slick-arrow"></a>',
            autoplay: true,
			autoplaySpeed: 8000,
			asNavFor: '.slider-sync-simple',
			adaptiveHeight: true,
			rtl: window.rtl,
			responsive: [
				{
					breakpoint: 660,
					settings: {
						speed: 500,
						adaptiveHeight: true,
					}
				},{
					breakpoint: 360,
					settings: {
						speed: 400,
						adaptiveHeight: true,
					}
				}
			]
		});
	},

	checkScrollPos: function() {
		if ($(document).scrollTop() > slider.syncComplex.offset().top) {
            videos.pauseOther();
            slider.syncComplex.slick('slickPause');
        }
	    $(window).scroll(function(event){
	        if ($(document).scrollTop() > slider.syncComplex.offset().top) {
	            videos.pauseOther();
	            slider.syncComplex.slick('slickPause');
	        } else {
	            slider.syncComplex.slick('slickPlay');
	        }
	    });
	},

	smallProducts: function() {
		var sliderContentProducts = $('.small-product-carousel');
		if (sliderContentProducts.length) {
			sliderContentProducts.each(function(){
				var _this = $(this);
				if (_this.hasClass('slick-initialized')) {_this.slick('unslick');}
				var swipeEnabled = true;
				if (_this.closest('.slider-sync-complex').length) {
					swipeEnabled = false;
				}
				_this.slick({
					useTransform: true,
					speed: 500,
					accessibility: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					swipe: swipeEnabled,
					rtl: window.rtl,
					prevArrow: '<a href="#" class="slick-prev slick-arrow"></a>',
					nextArrow: '<a href="#" class="slick-next slick-arrow"></a>',
				});
			});
		}
	},

	setupSingle: function() {
		slider.single.each(function() {
			$(this).slick({
				useTransform: true,
				speed: 800,
				mobileFirst: true,
				accessibility: true,
				arrows: false,
				autoplaySpeed: 6000,
				rtl: window.rtl,
				responsive: [
					{
						breakpoint: 660,
						settings: {
							speed: 200,
						}
					},{
						breakpoint: 360,
						settings: {
							speed: 200,
						}
					}
				]
			});
		});
	},

	setupProductCarousel: function() {
		slider.productCarousel.each(function() {
			var _this = $(this);
			_this.slick({
				useTransform: true,
				speed: 400,
				mobileFirst: true,
				accessibility: true,
				arrows: true,
				rtl: window.rtl,
				prevArrow: '<a href="#" class="slick-prev slick-arrow"></a>',
				nextArrow: '<a href="#" class="slick-next slick-arrow"></a>',
				appendArrows: _this.closest('.module-new')
			});
		});
	},

	autoplay: function() {
		slider.single.slick('slickPlay');
	},

	pause: function() {
		slider.single.slick('slickPause');
	},

	setupMultiItem: function() {
		var transformTrans;
		if (isTouch) {
			transformTrans = false;
		} else {
			transformTrans = true;
		}
		slider.multiItem.each(function() {
			$(this).slick({
				useTransform: transformTrans,
				speed: 500,
				accessibility: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				rtl: window.rtl,
				responsive: [
					{
						breakpoint: 660,
						settings: {
							slidesToShow: 2
						}
					},{
						breakpoint: 360,
						settings: {
							slidesToShow: 1
						}
					}
				]
			});
		});
		slider.multiItem4.each(function() {
			$(this).slick({
				rtl: rtlSet,
				useTransform: true,
				speed: 500,
				accessibility: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 800,
						settings: {
							slidesToShow: 3
						}
					},{
						breakpoint: 600,
						settings: {
							slidesToShow: 2
						}
					},{
						breakpoint: 400,
						settings: {
							slidesToShow: 1
						}
					}
				]
			});
		});
	},

	setupMultiCentredItem: function() {
		slider.multiCentredItem.each(function() {
			$(this).slick({
				useTransform: true,
				speed: 500,
				accessibility: true,
				centerMode: true,
				centerPadding: '100px',
				rtl: window.rtl,
				slidesToShow: 3,
				responsive: [
					{
						breakpoint: 950,
						settings: {
							centerMode: false,
							slidesToShow: 3
						}
					},{
						breakpoint: 700,
						settings: {
							centerMode: false,
							slidesToShow: 2
						}
					},{
						breakpoint: 500,
						settings: {
							centerMode: false,
							slidesToShow: 1
						}
					}
				]
			});
		});
	},

	eventListeners: function() {
		slider.syncSimple.on('beforeChange', function(event, slick, currentSlide, nextSlide) {

			var $nextSlideEl = slider.syncSimple.find(('[data-slick-index="'+nextSlide+'"]'));
			var $nextSlideVideo = $nextSlideEl.find('.video-cover');
			// Don't play video if touch device
			if (isTouch) { slider.handleMobile(); return; }
			slider.moveContentDown(nextSlide);
			slider.videosInSliders(nextSlide);
		});
	},

	pauseInputFocus: function() {
		slider.inputField.focusin(function() {
			if (windowWidth >= desktop) {
				slider.pause();
			}
		});
		slider.inputField.focusout(function() {
			if (windowWidth >= desktop) {
				slider.autoplay();
			}
		});
	},

	pauseOnHover: function() {
		slider.single.mouseenter(function() {
			if (windowWidth >= desktop) {
				slider.pause();
			}
		});
		slider.single.mouseleave(function() {
			if (windowWidth >= desktop && !slider.inputField.is(':focus')) {
				slider.autoplay();
			}
		});
	},

	desktopOnly: function() {
		// slider.autoplay();
		// slider.pauseOnHover();
	},

	checkfirstSlideVideo: function() {
		var homeCarousel_top = $('.slider-hero-extended');
		if (homeCarousel_top) {
			var firstSlide = homeCarousel_top.find('.slide-hero-1');
			var firstVideo = firstSlide.find('video');

			if (firstVideo.length) {
				$('.slider-hero-content').addClass('move-down')
			}
		}
	},

	init: function() {
		console.log('sliders init');

		if ($('.slider-hero-extended').length) {
			slider.setupSync();
			console.log('setupSync');
		}

		if ($('.small-product-carousel').length) {
			slider.smallProducts();
			console.log('smallProducts');
		}

		slider.setupSingle();
		slider.setupMultiItem();
		slider.setupMultiCentredItem();
		slider.pauseInputFocus();
		slider.autoplay();
		slider.pauseOnHover();
		slider.eventListeners();

		if (windowWidth >= desktop) {
			slider.desktopOnly();
		}

		if (slider.syncSimple.length) {
			$firstSlideVideo = slider.syncSimple.find(('[data-slick-index="0"]')).find('.video-cover');
			if ($firstSlideVideo.length)
				videos.playVideo($firstSlideVideo);
		}

		if (slider.productCarousel.length) {
			console.log('setupProductCarousel');
			slider.setupProductCarousel();
		}

		slider.checkfirstSlideVideo();

	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = slider;
}
