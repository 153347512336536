//  ***********************
//  $$ Smart resize
//  ***********************
(function ($, sr) {
  var debounce = function (func, threshold, execAsap) {
    var timeout;
    return function debounced() {
      var obj = this,
        args = arguments;
      function delayed() {
        if (!execAsap) {
          func.apply(obj, args);
        }
        timeout = null;
      }
      if (timeout) {
        clearTimeout(timeout);
      } else if (execAsap) {
        func.apply(obj, args);
      }
      timeout = setTimeout(delayed, threshold || 500);
    };
  };
  jQuery.fn[sr] = function (fn) {
    return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
  };
})(jQuery, 'smartresize'); // End smartresize

(function ($, window) {
  function factory(slider, nav, form, ellipsisNew) {
    $(window).smartresize(function () {
      window.newWindowWidth = window.innerWidth;
      window.newWindowHeight = window.innerHeight;
    
      //	responsive-helpers.js
      // bh.setHelpers(newWindowWidth, newWindowHeight);
    
      // var widthChange = Math.abs(newWindowWidth - windowWidth);
    
      //	Add functions here
    
      if (window.windowWidth >= window.desktop && window.newWindowWidth < window.desktop && !window.isTouch) {
        //	sliders.js
        slider.pause();
        //	nav.js
        nav.openClose.closeNavResizeDown();
        nav.openClose.offCanvasScroll();
        nav.openClose.undoDisableSearch();
      }
    
      if (window.windowWidth < window.desktop && window.newWindowWidth >= window.desktop && !window.isTouch) {
        //	nav.js
        nav.openClose.undoMobile();
        nav.openClose.closeNavResize();
        nav.openClose.undoOffCanvasScroll();
    
        //	sliders.js
        slider.desktopOnly();
    
        //	forms.js
        form.keyboardAmends();
      }
    
      if ((window.isTouch || (!window.isTouch && window.windowWidth < window.desktop)) && $('.site-canvas.active').length > 0) {
        // nav.js
        nav.openClose.cutHeight(window.newWindowHeight);
      }
    
      //	ellipsis.js
      $('.ellipsis-new').each(function(){
        ellipsisNew.applyEllipsis($(this), true);
      });
      if (ellipsisNew.ellipsisTitleEl.length){
        ellipsisNew.ellipsisTitleEl.each(function(){
          ellipsisNew.applyEllipsisTitle($(this), true);
        });
      }
    
      window.windowWidth = window.innerWidth;
      window.windowHeight = window.innerHeight;
    });
  }

  if (typeof exports === 'object' && typeof module === 'object') {
    module.exports = factory;
  } else {
    factory(slider, nav, form, ellipsisNew);
  }
}(jQuery, window));
