var accordionForm = {
	accordion: $('.accordion-form'),
	firstBlock: $('.accordion-form.first'),
	collapsed: $('.accordion-form.open-wen-filled'),

	dependantTrigger: $('.hidden-trigger'),

	checkFirstFilled: function() {
		if (accordionForm.collapsed.length) {
			var requiredEls = accordionForm.firstBlock.find('[data-required="true"]');
			requiredEls.on('change', function(){
				var empty = requiredEls.filter(function() {
			        return this.value === "";
			    });
			    if(!empty.length) {
			        accordionForm.openCollapsed();
			    }
			});
			var empty = requiredEls.filter(function() {
		        return this.value=="";
		    });
		    if(!empty.length) {
		        accordionForm.openCollapsed();
		    }
		}
	},

	openCollapsed: function() {
		accordionForm.collapsed.find('.accordion-content').slideDown();
	},
	

	dependants: function() {
		accordionForm.dependantTrigger.on('change', function(){
			var targetId = $(this).attr('data-dependant');
			var targetEl = $('#' + targetId);
			if ($(this).is(':checked')) {
				targetEl.slideDown();
			} else {
				targetEl.slideUp();
			}
		});
	},

	init: function() {
		if (accordionForm.accordion) {
			accordionForm.checkFirstFilled();
			accordionForm.dependants();
		}
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = accordionForm;
}
