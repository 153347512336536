(function ($) {
  function factory() {
    // Not used
    // $(window).scroll(function(event){
    //     didScroll = true;
    // });
    
    // if ($('.slide-content-header ').length && !$('.slide-content-header.hide-arrow').length) {
    //     $(window).scroll(function(event){
    //         if ($(document).scrollTop() > 100) {
    //           //  $('.slide-content-header').addClass('hide-arrow');
    //         }
    //     });
    // }
  }

  if (typeof exports === 'object' && typeof module === 'object') {
    module.exports = factory;
  } else {
    factory();
  }
}(jQuery));
