/**
 * @accordion_progression
 * This function / class is the logic behind the progression of a users
 * journey when filling in a form
 *
 * @Description:
 * On initial load of a page, the first section of the form is displayed.
 * When all required fields are filled in, the next section of the form is displayed.
 * This process is repeated, for all sections of the form.
 *
 * This functionality is only for the booking form!
 *
 * @Author: Bluegrass Digital
 */

 var accordion_progression = {

	// global vars
	globals: {
		requiredFields: $(':input[data-required="true"]'),
		sections: $('.accordion-wrap'),
		sectionsIndex: 0,
		globalCheck: [],
		masterIndex: 0
	},

	run: function(section, checklist, reval){

		// assigned object to a variable since the name is longer.
		var $self = accordion_progression;

		// create a check list for our filled in fields.
		// by default everything will be not filled in for required fields
		// our checklist logic is simple
		// get the length of required fields
		// for every filled in field for that section
		// add an index to our checklist
		// on validation of the section
		// compare the size of the check list
		// to the size of the required fields
		// if they dont match then well you know what that means :P
		var checklist = [];

		console.log('run... ');
		console.log('sequencing...');
		console.log('attaching events...');

		// add the current class to the section we're on right now
		$(section).addClass('current');

		// add a section id
		$(section).attr('data-section-id',$self.globals.masterIndex);

		// only if it's not the first section
		// since the first section is always shown
		if( ! ($(section).hasClass('first')) ){

			// add the shown class, to say that it is shown
			$(section).addClass('shown');

		}

		// sequence first section
		$self.sequence( section, checklist, reval );

	},

	nextStep: function(sections, checklist, section, skipping){

		// assigned object to a variable since the name is longer.
		var $self = accordion_progression;

		// get the global checklist
		var masterCheck = $self.globals.globalCheck;

		// get lenghth of the required fields for this section
		var requiredSize = $(section).find($self.globals.requiredFields).length;

		// but sometimes we may not be valid
		// in that case we hide everything after a certain section
		var previouslyValid = checklist.indexOf('valid');

		var listLength = checklist.length;

		if( previouslyValid > 0 ){

			var listLength = checklist.length-2;

		}

		// if the checklist is a 1:1 match of the required fields array
		if( listLength == requiredSize ){

			if( previouslyValid < 0 ){

			    // increment our master index, coz we're going to a new section
			    $self.globals.masterIndex++;

			    checklist.splice(0,1,"valid");

			    $self.showSection($self.globals.sections[$self.globals.masterIndex]);

			}else{

				var data = parseInt($(section).attr('data-section-id'));
				var pInvalid = false;


				if( ! data == 0 ){

					pInvalid = true;
					$(section).removeClass('invalid');

				}

				// here we show everything past this point
				$( $self.globals.sections ).each(function(index, el){

					if( ($(el).hasClass('was-dependant')) && ! ($(el).hasClass('must-not-be-shown')) ){

						$(el).removeClass('hidden-dependant was-shown');
						$(el).addClass('shown');

					}else if( ($(el).hasClass('was-shown')) && ! ($(el).hasClass('must-not-be-shown')) ){

						$(el).addClass('shown');
						$(el).removeClass('was-shown open-wen-filled');

					}

				});

				// everything past the point of invalid
				// should be shown if it previously had the class of
                if( pInvalid ){

                	var sI = parseInt($(section).attr('data-section-id'));
                	var eI = sections.length;

                	if( $('.invalid').length > 0 ){

                		eI = parseInt( $('.invalid').attr('data-section-id') );

                	}

                	// we hide everything from the point forward
	                for( var i = sI+1; i < eI+1; i++ ){

	                	if( ($(sections[i]).hasClass('must-not-be-shown')) && ($(sections[i]).hasClass('was-dependant')) ){

	                		$(sections[i]).addClass('shown');
	                		$(sections[i]).removeClass('must-not-be-shown hidden-dependant');

	                	}else if( $(sections[i]).hasClass('must-not-be-shown') ){

	                		$(sections[i]).addClass('shown');
	                		$(sections[i]).removeClass('must-not-be-shown open-wen-filled');

	                	}

	                }

                }



			}

		}else{

			if( previouslyValid >= 0 ){

				var data = parseInt($(section).attr('data-section-id'));

				if( ! data == 0 ){
					$(section).addClass('invalid');
				}

				// here we hide everything past this point
				var sections = $self.globals.sections;
				var curntSection = parseInt($(section).attr('data-section-id'));

                // we hide everything from the point forward
                for( var i = curntSection+1; i < sections.length; i++ ){

                	if( ($(sections[i]).hasClass('was-dependant')) && ! ($(sections[i]).hasClass('must-not-be-shown')) ){

                		$(sections[i]).addClass('was-shown hidden-dependant');
                		$(sections[i]).removeClass('shown');

                	}else if( $(sections[i]).hasClass('shown') ){

                		$(sections[i]).addClass('was-shown open-wen-filled');
                		$(sections[i]).removeClass('shown');

                	}

                }

                // everything past the point of invalid should not be shown unless explicitly told
                if( $(section).hasClass('invalid') ){

                	// we hide everything from the point forward
	                for( var i = curntSection+1; i < sections.length; i++ ){

	                	if( $(sections[i]).hasClass('was-shown') ){

	                		$(sections[i]).addClass('must-not-be-shown');
	                		$(sections[i]).removeClass('was-shown');

	                	}

	                }

                }

            }

			// we do nothing... damn lots to read huh!
			// unless we're skipping a section
			// then we show the next section
			if(skipping){

				// increment our master index, coz we're going to a new section
				$self.globals.masterIndex++;
				$self.showSection($self.globals.sections[$self.globals.masterIndex]);

			}

		}

	},

	scrollTo: function(section){

		$('html, body').animate({

	        scrollTop: $(section).offset().top-150

	    }, 2000);

	},

	secondaryTrigger: function(){

		// assigned object to a variable since the name is longer.
		var $self = accordion_progression;

		var field = $('.secondary-trigger').find('input.hidden-trigger');
		$self.validateTrigger(field);
		$('.secondary-trigger').removeClass('open-wen-filled');
		$('.secondary-trigger').addClass('shown');

	},

	showSection: function(section){

		// assigned object to a variable since the name is longer.
		var $self = accordion_progression;

		if( $(section).hasClass('open-wen-filled') ){

			$(section).removeClass('open-wen-filled');
			$(section).addClass('shown');
			$('#additionalServices').show();
			// $self.scrollTo(section);

		}

		$self.sequence(section, [], false);

	},

	sequence: function(section, checklist, reval){

		// assigned to a variable since the name is longer.
		var $self = accordion_progression;

		var fields;

		if( $(section).hasClass('hidden-dependant') ){

			fields = [];

		}else if ( $(section).hasClass('fully-valid') ){

			fields = [];

		}else{

			// required fields for section
			fields = $(section).find($self.globals.requiredFields);

		}

		// check if we have any fields to validate
		if( fields.length > 0 ){

			// attach events
			$self.attachEvents( fields, section, checklist );

		}else{

			// run our next section method
			$self.nextStep($self.globals.sections, [], section, true);

		}

	},

	attachEvents: function(elemArray, section, checklist){

		// assigned to a variable since the name is longer.
		var $self = accordion_progression;

		var filledIn = false;

		// get first section fields
		if( elemArray.length > 0 ){

			// cycle through each field and attach an event to validate
			elemArray.each(function( i ){

				$(this).addClass('not-filled-in');
				$self.validate(this, checklist, section);

			});

		}

	},

	validateSelect: function(elem, checklist, section){

		// assigned to a variable since the name is longer.
		var $self = accordion_progression;


		// since we know this is a select, we can use the change event listener
		$(elem).change(function(){

			// we'll add current class to the section
			// we're on in real-time
			// so our DOM is always up to date.
			if(! $(section).hasClass('current') ) {

				// remove all other current sections
				// coz our user has changes their position in the form
				$($self.globals.sections).removeClass('current');

				// update DOM with correct info
				$(section).addClass('current');

				var data = $(section).attr('data-section-id');

				if( ! data ){

                    // add a section id
                    $(section).attr('data-section-id',$self.globals.masterIndex);

                }

            }

			// on change get the selected value
			// if not empty, add filled-in class
			if( ! $(this).val() == "" ){

				// check if field was previously not-filled-in
				if( $(this).hasClass('not-filled-in') ){

					// only add to the checklist if this has not been filled in
					// add in our index to the checklist for this section
					checklist.push( $(this).val() );

					// since it's no longer valid - remove the class
					$(this).removeClass('not-filled-in');

				}

				$(this).addClass('filled-in');

				// run our next section method
				$self.nextStep($self.globals.sections, checklist, section);

			}else{

				// check if field was previously filled-in
				if( $(this).hasClass('filled-in') ){

					// since it's no longer valid - remove the class
					$(this).removeClass('filled-in');

					// remove an index from the checklist for this section
					checklist.splice(-1, 1);

				}

				// otherwise add not-filled-in class
				$(this).addClass('not-filled-in');

				// run our next section method
				$self.nextStep($self.globals.sections, checklist, section);

			}

		});

	},

	validate: function(field, checklist, section){

		// check the type of input
		if( $(field).is('select') ){

			accordion_progression.validateSelect(field, checklist, section);

		}else {

			accordion_progression.validateInput(field, checklist, section);

		}

	},

	validateTrigger: function(field){

		$(field).on('change', function(){

			var secondaryDetails = $(this).attr('data-dependant');

			$('#'+secondaryDetails).toggleClass('hidden-dependant');

		});

	},

	validateInput: function(elem, checklist, section){

		// assigned to a variable since the name is longer.
		var $self = accordion_progression;

		// proper validation will be done in the back end.
		// we just need to check that these fields aren't empty

		$(elem).on('change', function(e){

			// we'll add another current class to the section
			// we're on in real-time
			// so our DOM is always up to date.
			if(! $(section).hasClass('current') ) {

				// remove all other current sections
				// coz our user has changes their position in the form
				$($self.globals.sections).removeClass('current');

				// update DOM with correct info
				$(section).addClass('current');

				var data = $(section).attr('data-section-id');

				if( ! data ){

                    // add a section id
                    $(section).attr('data-section-id',$self.globals.masterIndex);

                }
            }

			// console.log( $(this).val() + ' = value' ); used for debugging
			if( $(this).val() == '' ){

				// make sure we first check if it was previously filled in
				if( $(this).hasClass('filled-in') ){

					// get the index of the field being removed
					var item = checklist.indexOf( $(this).text() );

					// remove an index checklist the checklist for this section
					checklist.splice(item, 1);

					$(this).removeClass('filled-in');

				}

				// if empty add not-filled-in class
				$(this).addClass('not-filled-in');

				// run our next section method
				$self.nextStep($self.globals.sections, checklist, section);

			}else{

				// hold your horses homie!
				// make sure we first check if it was previously not filled in
				if( $(this).hasClass('not-filled-in') ){

					// add index checklist the checklist for this section
					checklist.push( $(this).text() );

					$(this).removeClass('not-filled-in');

				}

				// obviously this would be filled in
				$(this).addClass('filled-in');

				// run our next section method
				$self.nextStep($self.globals.sections, checklist, section);

			}

		});

	},

	checkAdditionalServices: function() {
		var additionalServices = $('#additionalServices');
		if ($('#GroupType').val() === 'MICE') {
			additionalServices.show();
			additionalServices.addClass('shown');
		} else {
			// additionalServices.hide();
			additionalServices.removeClass('shown');
		}
	},

	init: function(){

		// assigned to a variable since the name is longer.
		var $self = accordion_progression;

		$self.checkAdditionalServices();
		$('#GroupType').on('change', $self.checkAdditionalServices);

		// get first section
		// start of the sequence with the first section
		var firstSection = $self.globals.sections[$self.globals.sectionsIndex];

		$self.secondaryTrigger();

		// console.log(fields);
		$self.run( firstSection, false );

	}

};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = accordion_progression;
}
