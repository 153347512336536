var search = {

	elms: {
		searchInput: $('.smart-search'),
		searchSubmit: $('.smart-search-submit'),
		brandsList: $('.brands'),
		brandItem: $('.brands-list-item'),
		productsList: $('.products'),
		productItem: $('.product'),
		item: null,
		itemsArr: null
	},
	page: null,

	runSearch: function( value ) {
		console.log( 'search: runSearch:' + value );

		// Remove some classes to start a fresh search
		$('.no-matches').removeClass('show');
		$('.title-all-items').addClass('hide');
		search.elms.item.removeClass('search-match');

		var returnMatches = false;
		var inputValue = normalizeText(value);
		console.log('Input normalized: ' + inputValue + ' vs: ' + value);

	    // Gets input value and removes non latin characters and spaces
		// Helper to normalize text for searching, replacing special characters and making lowercase
		function normalizeText(text) {

		    return text.toLowerCase().replace(/[^a-z\u00E0-\u02A8\d ]+/g, '').replace(/ /g, '').replace(/&/, ' ');
		}

		// If search box has a value
		if (inputValue !== '') {

			// Iterate over items
			search.elms.item.each( function( index ) {

				// Cache $this to use later
				var $currentItem = $(this);
				// Set a regex for the input match
				var inputRegex = '^.*' + inputValue + '.*$';

				// Function to search by the word passed in
				function wordSearch( word ) {
					// Get the data attr to search for
					var dataAttr = '[data-' + word + ']';

					// Find the element with data-brand attribute and get the value
					// Then, normalize the text with the helper function
					word = normalizeText( $currentItem.find(dataAttr).andSelf().filter(dataAttr).data(word) );

					if ( word.match(inputRegex) ) {
						// Add attribute to flag it's a match
						$currentItem.addClass('search-match');
						returnMatches = true;
					}
				}

				wordSearch( 'brand' );

				// Hide elements that don't have the class search-match
				if ( !$currentItem.hasClass('search-match') ) {
					$currentItem.addClass('filter-hidden').hide();
				}

				// Hide Promo box
				//console.log('hide promo box');
				$('article.component-wrapper-new.component-img-content.component-promotion').css('display','none');

			});
		} else {
			$('.title-all-items').removeClass('hide');
			returnMatches = true;
		}

		if ( returnMatches === false ) {
			$('.no-matches').addClass('show');
			$('.title-all-items').removeClass('hide');
			search.elms.item.removeClass('filter-hidden').show();
		}

		// If blocks (on brands page) toggle the blocks
		if (filter.elms.blockElms) {
			filter.updateBlockDisplay();
		}
		if (filter.elms.blocknavElms) {
			filter.scrolltoNav();
		}

		search.clearSearchInput();

		$.publish('/filters/updated', [ {} ]);
	},

	eventListeners: function() {

		search.elms.searchSubmit.on('click', function(event) {
			event.preventDefault();
			// First, clear any filters
			filter.clearFilters(event);
			// Run the search, passing in the item element
			search.runSearch( search.elms.searchInput.val() );
		});
	},

	clearSearchInput: function() {
		search.elms.searchInput.val('');
		search.elms.searchInput.typeahead('val', '').blur();
		// Hide Promo box
		//console.log('clearSearchInput display promo box');
		//$('article.component-wrapper-new.component-img-content.component-promotion').css('display','block');

	},

	detectPage: function() {

		// Set up variables to define, dependant on page
		var itemsArr = [];

		// The item element is dependent on which page we are on
		if ( $('.page-products').length > 0 ) {
			search.page = 'products';
			search.elms.item = search.elms.productItem;
		}
		if ( $('.page-brands').length > 0 ) {
			search.page = 'brands';
			search.elms.item = search.elms.brandItem;
		}

		// Only perform the rest if item is defined
		if ( search.elms.item !== null ) {

			// Once we have the item we can create an array of item names
			search.elms.item.each( function( index ) {
				itemsArr.push($(this).find('[data-brand]').attr('data-brand'));
			});

			// Pass the page and the item element to the event listeners
			if ( search.page === 'brands' )
				search.eventListeners();

			// Pass the compiled array to the elms object to make it globally accessible
			search.elms.itemsArr = itemsArr;

			console.log('itemsArr::' + itemsArr);
			// Initialize typeahead.js and pass in array
			typeahead.init(itemsArr);
		}
	},

	expandSpecial: function() {
		$('.search-results-form.collapsed .smart-search-submit').click(function(e) {
			e.preventDefault();
			$('.search-results-form.collapsed').removeClass('collapsed').find('.smart-search').focus();
		});
	},

	init: function() {

		// If search box is present
		if (search.elms.searchInput.length > 0) {
			search.detectPage();
		}

		if ($('.search-results-form.collapsed').length) {
			search.expandSpecial();
		}
	}

};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = search;
}
