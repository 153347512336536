var booking = {
    villageData: null,
    selectedVillageData: undefined,
    dateOfEventEl: $('#dateOfEvent'),
    timeArrivalEl: $('#time_arrival'),
    timeDepartureEl: $('#time_departure'),
    selectedDate: undefined,
    timepickersPopulated: false,

    populateServices: function() {
        var services = booking.selectedVillageData.services;

        var checkboxes = $('#additionalServices').find('.checkbox-wrap');
        var inputs = checkboxes.find('input[type="checkbox"]');

        inputs.prop('checked', false);

        // hide all services checkboxes
        checkboxes.each(function(){ $(this).addClass('hide'); });

        for (index in services) {
            if (services[index] == null) { continue; }
            var serviceOption_name = services[index].name;
            var option_treated = serviceOption_name.toString().replace(/ /g,'').replace('?','').toLowerCase();

            checkboxes.each(function(){
                var _this = $(this);
                var text = _this.find('label').text();
                var text_treated = text.toString().replace(/ /g,'').replace('?','').toLowerCase();
                var input = _this.find('input[type="checkbox"]');

                if (text_treated === option_treated) {
                    _this.removeClass('hide');
                }
            });
        }
    },

    updateTimepickers: function() {
        var day = booking.selectedVillageData.openingHours.filter(function(item){return item.dayIndex == booking.selectedDate.getDay()})[0];
        if (!day){
            console.error('Cannot find date information for current village');
            return;
        }

        // Check for exceptions dates
        var selectedDate = booking.selectedDate;
        var m = selectedDate.getMonth();
        var d = selectedDate.getDate();
        var y = selectedDate.getFullYear();

        function date2digist(date) {
            return (date < 10 ? '0' : '') + date;
        }

        // First convert the date in to the mm/dd/yyyy format
        // Increment the month count by 1
        var currentdate = date2digist(m + 1) + '/' + date2digist(d) + '/' + y;

        // Get the selected village
        var selectedVillage = $('#SelectedVillage').val().normalize('NFD').replace(/\s/g, '').replace(/[\u0300-\u036f]/g, "");
        // Get exceptions to check if there is any closed day
        var exceptions = booking.villageData[selectedVillage].openingHoursExceptions;
        for (var i = 0; i < exceptions.length; i++) {
            // Check if the exception is closed all day
            if (currentdate == exceptions[i].date) {
                day = exceptions[i];
            }
        }

        var minTime = day.open
        var maxTime = day.close

        if (booking.timepickersPopulated){
            booking.timeArrivalEl.timepicker('option', 'minTime', minTime);
            booking.timeArrivalEl.timepicker('option', 'maxTime', maxTime);
            booking.timeDepartureEl.timepicker('option', 'minTime', minTime);
            booking.timeDepartureEl.timepicker('option', 'maxTime', maxTime);
        }else{
            booking.timeArrivalEl.timepicker({
                timeFormat: 'H:i',
                disableTextInput: true, // this does not prevent mobile keyboard from displaying
                // disableTouchKeyboard: true,
                useSelect: Modernizr.touch,
                step: 15,
                minTime: minTime,
                maxTime: maxTime
            }).on('changeTime', function() {
                //ON CHANGE TIME
                booking.timeDepartureEl.timepicker('option', 'minTime', $(this).val());
            });
            booking.timeDepartureEl.timepicker({
                timeFormat: 'H:i',
                disableTextInput: true, // this does not prevent mobile keyboard from displaying
                useSelect: Modernizr.touch,
                step: 15,
                minTime: minTime,
                maxTime: maxTime
            }).on('changeTime', function() {
                //ON CHANGE TIME
                booking.timeArrivalEl.timepicker('option', 'maxTime', $(this).val());
            });

            booking.timepickersPopulated = true;
        }
    },

    clearTimepickers: function() {
        booking.timeArrivalEl.val('');
        booking.timeDepartureEl.val('');
    },

    processDayIndex: function(openingHours) {
        for (var i = 0; i < openingHours.length; i++) {
            var dayObject = openingHours[i];
            var dayName = dayObject.day;

            switch(dayName) {
                case 'Monday':
                    openingHours[i].dayIndex = 1;
                    break;
                case 'Tuesday':
                    openingHours[i].dayIndex = 2;
                    break;
                case 'Wednesday':
                    openingHours[i].dayIndex = 3;
                    break;
                case 'Thursday':
                    openingHours[i].dayIndex = 4;
                    break;
                case 'Friday':
                    openingHours[i].dayIndex = 5;
                    break;
                case 'Saturday':
                    openingHours[i].dayIndex = 6;
                    break;
                case 'Sunday':
                    openingHours[i].dayIndex = 0;
                    break;
            }
        }

        return openingHours;
    },

    isClosed: function(dayIndex) {
        // console.log('booking.selectedVillageData: ', booking.selectedVillageData)
        // console.log('booking.selectedVillageData, dayIndex: ', dayIndex)
        var openingHours = booking.processDayIndex(booking.selectedVillageData.openingHours);
        var index = openingHours.map(function(day) { return day.dayIndex; }).indexOf(dayIndex);
        if (index === -1){
            throw "There was a problem with the opening hours data for current village";
            booking.setDefaultTimePickerValues();
            booking.setDefaultDatePickerValues();
            return null
        }

        return booking.selectedVillageData.openingHours[index].isClosed;
    },

    checkIfDisabled: function(date, callback) {
        // Check if is closed
        if (booking.isClosed(date.getDay())) {
            // if true, jump to next day and check again
            booking.checkIfDisabled(date.addDays(1), callback);
        } else {
            // if false, check if exception is closed
            if (!booking.disableSpecificDates(date)[0]) {
                // if true, jump to next day and check again
                booking.checkIfDisabled(date.addDays(1), callback);
            } else {
                // if false, set as selected date
                callback(date);
            }
        }
    },

    updateVillage: function() {
        // Selected Village without spaces and accented letters
        var selectedVillage = $('#SelectedVillage').val().normalize('NFD').replace(/\s/g, '').replace(/[\u0300-\u036f]/g, "");

        // if no village data, return
        if (!booking.villageData[selectedVillage]) { return; }

        try {
            
            booking.selectedVillageData = booking.villageData[selectedVillage];

            if (!booking.selectedVillageData) {
                console.error('Cannot get data for village ' + $('#SelectedVillage').val())
                booking.setDefaultTimePickerValues();
                booking.setDefaultDatePickerValues();
                return;
            }

            var date = new Date();
            var counter = 0;

            try {
                while (booking.isClosed(date.getDay())) {
                    date = date.addDays(1)
                    if (counter > 7) {
                        console.log("Couldn't find any open dates");
                        return;
                    }
                    counter++;
                }
            } catch (e) {
                console.error(e)
                booking.setDefaultTimePickerValues();
                booking.setDefaultDatePickerValues();
                return;
            }

            booking.checkIfDisabled(date, function(returnedDate) {
                booking.selectedDate = returnedDate;
                // booking.dateOfEventEl.datepicker({
                //     beforeShowDay: booking.disableSpecificDates
                // });
                booking.dateOfEventEl.datepicker({
                    dateFormat: 'dd-mm-yy',
                    minDate: 0,
                    beforeShowDay: booking.disableSpecificDates
                });

                // booking.selectedDate = booking.checkIfDisabled(booking.selectedDate);
                // booking.dateOfEventEl.datepicker('setDate', booking.selectedDate);
                booking.populateServices();
                booking.updateTimepickers();
                booking.clearTimepickers();
            });
        } catch (e) {
            console.error("Could not update Village", e);
            booking.setDefaultTimePickerValues();
            booking.setDefaultDatePickerValues();
        }
    },

    disableSpecificDates: function(date) {

        var m = date.getMonth();
        var d = date.getDate();
        var y = date.getFullYear();

        function date2digist(date) {
            return (date < 10 ? '0' : '') + date;
        }

        // First convert the date in to the mm/dd/yyyy format
        // Increment the month count by 1
        var currentdate = date2digist(m + 1) + '/' + date2digist(d) + '/' + y;

        // Get the selected village
        var selectedVillage = $('#SelectedVillage').val().normalize('NFD').replace(/\s/g, '').replace(/[\u0300-\u036f]/g, "");
        // Get exceptions to check if there is any closed day
        var exceptions = booking.villageData[selectedVillage].openingHoursExceptions;
        for (var i = 0; i < exceptions.length; i++) {
            // Check if the exception is closed all day
            var exceptionClosed = exceptions[i].closedAllDay;
            if (exceptionClosed) {
                // Now check if the current date matches the closed exceotion
                if (currentdate == exceptions[i].date) {
                    return [false];
                }
            } else {
                // Now check if the current date matches the closed exceotion
                if (currentdate == exceptions[i].date) {
                    return [true, ''];
                }
            }
        }

        // After checking exceptions now check daily openingHours
        // If a day of the week is closed, disable all in the calendar
        var isClosed = booking.isClosed(date.getDay())
        if (isClosed) {
            return [false];
        } else {
            return [true, ''];
        }
    },

    getData: function () {
        // console.log('getData')

        booking.timeArrivalEl.prop('readonly', false);
        booking.timeDepartureEl.prop('readonly', false);

        booking.villageData = window.villageData;

        // console.log(booking.villageData)

        if (!booking.villageData) {
            console.error("Could not get village data, using default time/datepicker values");
            booking.setDefaultTimePickerValues();
            booking.setDefaultDatePickerValues();
        }else{
            booking.updateVillage();
        }
    },

    setDefaultTimePickerValues: function() {
        if (booking.timepickersPopulated){
            booking.timeArrivalEl.timepicker('option', 'minTime', null);
            booking.timeArrivalEl.timepicker('option', 'maxTime', null);
            booking.timeDepartureEl.timepicker('option', 'minTime', null);
            booking.timeDepartureEl.timepicker('option', 'maxTime', null);
        }else{
            booking.timeArrivalEl.timepicker({
                timeFormat: 'H:i',
                disableTextInput: true,
                useSelect: Modernizr.touch,
                step: 15
            });
            booking.timeDepartureEl.timepicker({
                timeFormat: 'H:i',
                disableTextInput: true,
                useSelect: Modernizr.touch,
                step: 15
            });
        }
        booking.clearTimepickers();
    },

    setDefaultDatePickerValues: function() {
        // booking.dateOfEventEl.datepicker('setDate', new Date());
    },

    eventListeners: function() {
        // ON CHANGE VILLAGE
        $('#SelectedVillage').on('change', function(){
            var selectedVillage = $('#SelectedVillage').val().normalize('NFD').replace(/\s/g, '').replace(/[\u0300-\u036f]/g, "");
            if (booking.villageData[selectedVillage]) {
                showHideErrorMessage(false);
                booking.updateVillage();
            }
            else {
                showHideErrorMessage(true);
            }
        });

        // ON CHANGE DATE
        booking.dateOfEventEl.change(function() {
            if (booking.villageData) {
                var newDate = datestringToDate(booking.dateOfEventEl.val());
                booking.selectedDate = newDate;
                booking.updateTimepickers();
                booking.clearTimepickers();
            }
        });

        $('.ui-state-default').on('click', function(e){
            e.preventDefault();
        });
    },

    init: function() {
        if ($('#MakeABooking').length) {
            booking.getData();
            // booking.clearServices();
            booking.eventListeners();
            booking.updateVillage();
        }
        var selectedVillage = $('#SelectedVillage').val().normalize('NFD').replace(/\s/g, '').replace(/[\u0300-\u036f]/g, "");
        if (!booking.villageData[selectedVillage]) {
            showHideErrorMessage(true);
        }
    }
};

Date.prototype.addHours = function(h){
    this.setHours(this.getHours()+h);
    return this;
}

Date.prototype.subtractHours = function(h){
    this.setHours(this.getHours()-h);
    return this;
}

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

// convert a date string (format: DD-MM-YYYY, eg: 28-08-2018) to Date
function datestringToDate(date){
    date = date.split("-");
    var newDate=date[1]+"/"+date[0]+"/"+date[2];
    return new Date(newDate)
}

if (typeof exports === 'object' && typeof module === 'object') {
    module.exports = booking;
}

function showHideErrorMessage(toShowErrorMessage) {
    if (toShowErrorMessage) {
        $("#villageDataError").show();
        enableDisableElements(true);
        booking.clearTimepickers();
        $("#dateOfEvent").val('')
    }
    else {
        $("#villageDataError").hide();
        enableDisableElements(false);
    }
}
function enableDisableElements(toDisable) {
    $('.formElement').each(function () {
        if (toDisable) {
            $(this).attr("disabled", "disabled");
        }
        else {
            $(this).removeAttr("disabled", "disabled");
        }
    });
}
