var dd = {
	langVill: $('.lang-vill'),
	trigger: $('.dropdown-button'),
	ddContent: $('.dropdown-menu'),
	login: $('#login'),
	offCanvas: $('.off-canvas-wrapper'),

	animate: function() {
		dd.trigger.click(function(){
			$(this).toggleClass('active');
			$(this).siblings(dd.Content).clearQueue().stop().slideToggle();
			$('.dropdown-button.active').not($(this)).removeClass('active').siblings(dd.ddContent).slideUp();

			$('.weather-open .weather-extended').slideUp();

			$('.sidenav-navItem.active .sidenav-item-content').slideUp();
		});
	},

	ddLeave: function() {
		dd.langVill.find(dd.ddContent).mouseleave(function(){
			if (windowWidth >= desktop) {
				$(this).slideUp();
				$(this).siblings(dd.trigger).removeClass('active');
			}
		});
	},

	undoClickOut: function() {
		var container = $('.dropdown');
		$(document).click(function(event) {
			if(!$(event.target).closest(container).length) {
		        if($(container).find('.dropdown-button.active').length > 0) {
		            nav.undoOthers.undoDropdowns();
		        }
		    }
		});
	},

	init: function() {
		dd.animate();
		dd.ddLeave();
		dd.undoClickOut();
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = dd;
}
