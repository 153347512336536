var widgetCarousel = {

	wrapperEl: $('.widget-carousel-new:not(.product-carousel)'),
	carouselOpts: null,
	setAlignment: 'left',
	setGroupCells: null,

	itemsCheck: $('[data-carousel-min-items]'),

	checkSpecials: function() {
		var items = widgetCarousel.itemsCheck;

		items.each(function(){
			var _this = $(this);
			var num_items = _this.find('.carousel-cell').length;
			var notCarouselNum = _this.attr('data-carousel-min-items');

			if (num_items <= notCarouselNum) {
				if (notCarouselNum > 3) {
					_this.addClass('carousel-disable-xlarge');
					_this.closest('.component-latest-carousel').addClass('arrows-hidden-xlarge');
				} else {
					_this.addClass('carousel-disable-large');
					_this.closest('.component-latest-carousel').addClass('arrows-hidden-large');
				}
			}
		});

		widgetCarousel.setupSlider();
	},

	setOptions: function(carouselItem) {
		carouselItem.closest('.component-latest-carousel').addClass('carousel-set');

		if (carouselItem.hasClass('widget-carousel-news-feed')) {
			widgetCarousel.setAlignment = 'center';

			if (windowWidth >= tabletWide) {
				widgetCarousel.setGroupCells = 2;
			}
		}
		var autoPlay = false;
		var friction = 0.28;
		if (carouselItem.attr('data-carousel-autoplay') === "true") {
			autoPlay = 6000;
			friction = 0.4;
		}
		console.log(carouselItem, autoPlay);
		widgetCarousel.carouselOpts = {
			wrapAround: true,
			groupCells: widgetCarousel.setGroupCells,
			prevNextButtons: true,
			pageDots: false,
			cellAlign: widgetCarousel.setAlignment,
			imagesLoaded: true,
			watchCSS: true,
			autoPlay: autoPlay,
			friction: 0.35,
			dragThreshold: 10,
			freePlay: -15,
			rightToLeft: window.rtl

		}
	},

	setupSlider: function() {
		widgetCarousel.wrapperEl.each(function(){
			var _this = $(this);
			widgetCarousel.setOptions(_this);
			_this.flickity(widgetCarousel.carouselOpts);

			var ellipsisEl = _this.find('.ellipsis-new');
			if (ellipsisEl.length) {
				ellipsisEl.each(function(){
					ellipsisNew.applyEllipsis($(this));
				});
			}
		});
	},

	init: function() {
		widgetCarousel.checkSpecials();
	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = widgetCarousel;
}
