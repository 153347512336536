var ellipsisNew = {
	ellipsisEl: $('.ellipsis-new'),
	ellipsisTitleEl: $('.ellipsis-title'),

	// 2 lines. depends on h3 font-size. rendered bigger on IE
	ellipsisTitleMaxHeight: 58,

	applyEllipsis: function(ellipsisItem, isResize, carouselBreakpoint) {

		var _this = ellipsisItem;

		if(_this.prev('h3').attr('class')=='gallery-title'){
			ellipsisNew.setGalleryHeight(_this);
		}else{
		 	ellipsisNew.setElHeight(_this);
		}

		setTimeout(function(){
			if (isResize) {
				_this.trigger('update');
			} else {
				_this.dotdotdot();
			}
			ellipsisNew.removeEmpty();
		}, 500);
	},

	applyEllipsisTitle: function(ellipsisItem, isResize) {
		setTimeout(function(){

			if (isResize) {
				ellipsisItem.trigger('update');
			} else {
				ellipsisItem.dotdotdot();
			}
		}, 500);
	},

	setElHeight: function(_this) {

		var container;
		var container_blockContent = _this.closest('.module-content-wrapper');
		var container_textContent = _this.closest('.module-text-simple-new, .module-text-new');
		var isPromoComponent = _this
								.closest('.component-wrapper-new.component-promotion, .component-wrapper-new.component-latest-trends, .component-wrapper-new.component-editorial, .component-wrapper-new.content-list-item')
								.length;

		var debug = isPromoComponent;

		var isEditorialFeed = _this.closest('.editorial-feed-article').length;

		if (container_blockContent.length && !isPromoComponent) {
			container = container_blockContent;
		}
		if (container_textContent.length && !isPromoComponent) {
			container = container_textContent;
		}
		if (isPromoComponent) {
			if (windowWidth < tabletWide && !_this.closest('.component-latest-trends').length) {
				// console.log('not apply!!');
				return;
			}
			container = _this.closest('.module-content');
		}

		if (isEditorialFeed) {
			container = _this.closest('.media-col-right');
		}

		if (!(container_blockContent.length && !isPromoComponent) && !(container_textContent.length && !isPromoComponent) && !isPromoComponent && !isEditorialFeed) {
			container = _this.closest('div');
		}

		if (_this.closest('.col-content').length){
			container = _this.closest('.col-content-wrapper');
		}

		// if(debug){
		// 	console.log('container: ', container);
		// }

		var container_height = container.height();
		var otherels = container.find('> *');
		var otherels_height = 0;
		otherels.each(function() {
			otherels_height += $(this).height();
		});
		var this_height = _this.height();
		if (_this.closest('.component-wrapper-new').find('.module-find-out-more').length && isPromoComponent) {

			this_height = this_height - 12;
		}

		// if(debug){
		// 	console.log('container_height: ', container_height);
		// 	console.log('otherels_height: ', otherels_height);
		// 	console.log('this_height: ', this_height);
		// }

		var targetHeight = container_height - (otherels_height - this_height);
		if (container_blockContent.find('.module-separator').length){
			targetHeight -= container_blockContent.find('.module-separator').height();
		}

		if (container_textContent.length) {
			_this.css('max-height', targetHeight);
		} else {
			_this.height(targetHeight);
		}
	},

	setGalleryHeight: function (_this){
		var wrapper = _this.closest('.modal-gallery-content-wrapper');
		var wrapper_height = wrapper.height();
		var numbers_height = wrapper.find('.gallery-slide-counter').height();
		var title_height = wrapper.find('.gallery-title').height();
		var nav_height = wrapper.find('.gallery-slide-nav').height();
		if ( window.innerWidth < window.innerHeight ) {
			console.log(wrapper_height, numbers_height, title_height, nav_height);
 	   		targetHeight = wrapper_height - numbers_height - title_height - nav_height - 60;
		}else{
			targetHeight = 150;
		}

		_this.height(targetHeight);
	},

	removeEmpty: function () {
		ellipsisNew.ellipsisEl.each(function() {
			var _this = $(this);
			var ellipsisCheck = _this.text().trim().length;

			if (ellipsisCheck === 3) {
				_this.empty();
			}
		});
	},

	init: function () {
		if (!$('.page-gallery').length) {
			setTimeout(function(){
				ellipsisNew.ellipsisEl.each(function(){
					if (!$(this).closest('.module-widget-carousel-wrapper').length) {
						ellipsisNew.applyEllipsis($(this));
					}
				});
			}, 100);
		}

		if (ellipsisNew.ellipsisTitleEl.length){
			ellipsisNew.ellipsisTitleEl.each(function(){

				$(this).css('max-height', ellipsisNew.ellipsisTitleMaxHeight);

				ellipsisNew.applyEllipsisTitle($(this));
			});
		}
	}


};


if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = ellipsisNew;
}
