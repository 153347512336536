var mob = {
	closeSpd: 200,
	delay: 200,
	staggerDelay:{
		in: 40,
		out: 0
	}
}

var mobileClosed = true;
var scrollPos = null;
var nav = {
	mainHeader: $('.main-header'),
	animItems: $('.off-canvas-block'),
	pinToTopItems: $('.nav-pin-to-top'),
	animDelay: 50,
	openClose: {
		hamburger: $('#navTrigger'),
		siteCanvas: $('.site-canvas'),
		siteWrapper: $('.site-wrapper'),
		offCanvas: $('.off-canvas-wrapper'),
		closeNav: $('.close-canvas'),
		lastItem:null,

		searchTrigger: {
			searchForm: $('.search-form'),
			button: $('.search-button'),
			inputSearch: $('#search')
		},

		subnavEls: {
			//subnavTrigger: $('.nav-subnav-trigger'),
			subnavTrigger: $('.nav-subnav-trigger, .language-subnav-trigger, .village-subnav-trigger, .social-subnav-trigger'),
			subnav: $('.nav-subnav')
		},

		showNav: function() {
			nav.openClose.showNav.commonElements = nav.openClose.hamburger
				.add(nav.openClose.siteCanvas)
				.add(nav.openClose.closeNav);

			nav.openClose.hamburger.click(function(e) {
				e.preventDefault();

				if (isTouch || (!isTouch && windowWidth < desktop)) {
					nav.openClose.showNav.commonElements.toggleClass('active');
					mobileClosed = false;
					scrollPos = $('body').scrollTop();
					// $('body, html, .site-wrapper, .site-canvas, #mainContent').css({'overflow': 'hidden', 'height': '100%'});
					//looks nicer with opacity on background as 0.2
					// $('.sidenav').css('background','none');
					nav.openClose.showInDelay(nav.animItems, /*nav.animDelay*/mob.staggerDelay.in,'in');
				}
			});

			nav.openClose.closeNav.click(function(e){
				e.preventDefault();
				nav.openClose.showNav.commonElements.toggleClass('active');
				nav.undoOthers.undoSubnav();
				nav.undoOthers.undoDropdowns();

				if (isTouch || (!isTouch && windowWidth < desktop)) {
					$('body, html, .site-wrapper, .site-canvas, #mainContent').removeAttr('style');
					mobileClosed = true;
					$('body').scrollTop( scrollPos );
					scrollPos = null;

					//remove class to reset animation
					setTimeout(function(){
						nav.openClose.showInDelay(nav.animItems, mob.staggerDelay.out,'out');
					}, 100)
				}
			});
		},

		offCanvasScroll: function() {
			nav.openClose.offCanvas.mCustomScrollbar({
				axis:'y',
				theme: 'minimal-dark',
				autoHideScrollbar: true,
				scrollInertia: 0
			});
		},

		undoOffCanvasScroll: function() {
			nav.openClose.offCanvas.mCustomScrollbar('destroy');
		},

		closeNavResize: function() {
			if (nav.openClose.closeNav.hasClass('active')) {
				nav.openClose.closeNav.trigger('click');
			}
		},

		closeNavResizeDown: function() {
			nav.openClose.hamburger.removeClass('active');
		},

		searchForm: function() {
		//	//console.log(' TEST ');

			if (!isTouch && windowWidth >= desktop) {
				nav.openClose.searchTrigger.inputSearch.attr('disabled', 'disabled');
			}

			nav.openClose.searchTrigger.button.click(function(e){
				e.preventDefault();
				nav.openClose.searchTrigger.inputSearch.removeAttr('disabled').focus();
				nav.openClose.searchTrigger.searchForm.addClass('active');
			});

			nav.openClose.searchTrigger.inputSearch.on('blur', function(){
				if (!isTouch && windowWidth >= desktop) {
					nav.openClose.searchTrigger.searchForm.removeClass('active');
					nav.openClose.searchTrigger.inputSearch.attr('disabled', 'disabled');
				} else {
					if ($(this).val() === '') {
						nav.openClose.searchTrigger.searchForm.removeClass('active');
					}
				}
			});
		},

		showInDelay: function (targetSelectors, incrementDelayBy, direction){
			// $('#mCSB_1_container').offset({top: 0});
			var d=0;
			targetSelectors.each(function(index,selector){

			  setTimeout(function(){
		        if(direction == 'in'){
					$(selector).addClass('movein');
				}
			  	if(direction == 'out'){
			  		$(selector).removeClass('movein');
			  	}
			  },d=d+incrementDelayBy);
			});
		},

		undoDisableSearch: function() {
			nav.openClose.searchTrigger.inputSearch.removeAttr('disabled');
		},

		mobileSubnav: function() {

			nav.openClose.subnavEls.subnavTrigger.on('click', function(e){

				nav.undoOthers.undoDropdowns();

				if (isTouch || (!isTouch && windowWidth < desktop)) {
					e.preventDefault();

					var navItem = $(this).closest('.nav-pin-to-top'); // navItem or dropdown item

					if (!navItem.hasClass('active')){
						//console.log(' OPEN SUBNAV ');

						//var $openedNavItem = navItem.siblings('.nav-pin-to-top.active');
						var $openedNavItem = $('.nav-pin-to-top.active');
						if ($openedNavItem.length){

							// close the one that's open
							nav.openClose.close(mob.closeSpd, $openedNavItem, navItem, function(navItem){
								//console.log(' close nav item callback, calling scrollTo ');
								nav.scrollTo(navItem);
							});

						} else {
							//console.log(' no open item, calling scrollTo ');
							nav.scrollTo(navItem);
						}

						navItem.addClass('active')
							.find('.nav-subnav').stop().slideDown()
						navItem.find('.nav-subnav-trigger').addClass('active');
					}else{
						//console.log(' CLOSE SUBNAV ', navItem);
						nav.openClose.close(mob.closeSpd, navItem, null, function(){
							setTimeout(function(){
								$('#mCSB_1_container').animate({top: 0});
							}, mob.delay)
						});
					}
					nav.undoOthers.undoDropdowns();
				}
			});
		},

		close: function(speed, openedNavItem, navItem, cb){
			//console.log('CLOSE');
			//console.log('navItem:',  navItem);

			if ( openedNavItem.is('#language') || openedNavItem.is('#village') || openedNavItem.is('#social') ){
				// quick fix: somewhere else in the code the active class is added so it needs a timeout (!!!)
				setTimeout(function(){
					openedNavItem.find('.offcanvas.webfont-cheveron').removeClass('active');
				},100);
			}

			openedNavItem.removeClass('active')
			.find('.nav-subnav').stop().slideUp(speed,'swing', function(){
				//console.log( '??? ',openedNavItem.hasClass('active') );
				if(!!cb){
					// openedNavItem.find('.nav-subnav-trigger, .language-subnav-trigger, .village-subnav-trigger').removeClass('active');
					openedNavItem.find('.nav-subnav-trigger').removeClass('active');
					cb(navItem);
				}
			});
		},

		undoMobile: function() {
			nav.openClose.subnavEls.subnav.removeClass('active');
		}
	},

	wrapNavItems: function() {
		// <ul class="tray-section-list"><li class="nav-navItem"></li></ul>
		var trays = $('.tray-section');

		trays.each(function(index){
			var _this = $(this);

			var subnavItems = _this.find('.nav-navLink');

			var wrapIndex
			if (subnavItems.length > 8) {
				wrapIndex = 3;
			} else {
				wrapIndex = 2;
			}

			// _this.addClass('tray-section-' + wrapIndex);

			for (var i = 0; i < subnavItems.length; i+=wrapIndex) {
				subnavItems.slice(i, i+wrapIndex).wrapAll('<ul class="tray-section-list"></div>');
			}

			subnavItems.wrap('<li class="nav-navItem"></li>');
		});
	},

	scrollTo: function(navItem){
		//console.log('scrollTo() navItem: ', navItem[0].innerText);

		setTimeout(function(){
			var menu = $('#mCSB_1_container');
			// var top = navItem.offset().top;
			var itemTop = navItem[0].getBoundingClientRect().top;
			var menuTop = menu[0].getBoundingClientRect().top;
			var targetTop = Math.round(itemTop - menuTop);
			//console.log('targetTop: ', targetTop);

			// don't scroll past the whole thing's height (avoid empty space below menu)
			var menuHeight =  menu.height();
			var diff = menuHeight - window.innerHeight;
			targetTop = (targetTop > diff) ? diff : targetTop;

			menu.animate({top: -targetTop});

		}, mob.delay);
	},

	anchorNav: function() {
		$('.anchor-nav').click(function(e) {
			e.preventDefault();
			var target = $(this.hash);
			var topbarOffset = isTouch && windowWidth >= desktop ? 80 : 50;
			$('html,body').animate({
				scrollTop: target.offset().top - topbarOffset
			}, 1000);
		});
	},

	undoOthers: {

		undoDropdowns: function() {
			$('.dropdown-button.active').removeClass('active').siblings('.dropdown-menu').slideUp();
		},

		undoSubnav: function() {
			$('.nav-subnav-trigger.active').removeClass('active').closest('.nav-navItem').removeClass('active').find('.nav-subnav').slideUp();
		},

		undoOnSearch: function() {
			nav.openClose.searchTrigger.inputSearch.focus(function() {
				nav.undoOthers.undoDropdowns();
				nav.undoOthers.undoSubnav();
			});
		},
	},

	init: function() {
		//console.log('nav.init()');

		// cache offset-top values for 'pin to top' when open
		// nav.pinToTopItems.each(function(){
		// 	var offsetTop = Math.round($(this)[0].getBoundingClientRect().top);
		// 	////console.log('caching offset-top:', offsetTop);
		// 	$(this).attr('data-offset-top', offsetTop);
		// });

		nav.openClose.showNav();
		nav.openClose.searchForm();
		nav.openClose.mobileSubnav();
		nav.wrapNavItems();
		nav.anchorNav();
		nav.undoOthers.undoOnSearch();

		if (isTouch || (!isTouch && windowWidth < desktop)) {
			nav.openClose.offCanvasScroll();
		}

	    //set up the arrow anim
	    // if osx then use a webfont
		if (isiPhone > -1 || isiPad > -1 || isiPod > -1) {
		    $("span.arrow-anim").html('<i class="webfont-arrow-long"></i>')
		} else {
		    $("span.arrow-anim").html('<div class="long-arrow"><svg version="1.1" id="long-arrow" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 958 500"  enable-background="new 0 0 958 500" xml:space="preserve"><g id="Your_Icon"><polygon class="st0" points="754.5,84.1 906,235.7 3.8,235.7 3.8,260.2 906.1,260.2 754.5,412 771.8,429.4 935.7,265.4 953.1,248,935.7,230.6 771.8,66.7"/></g></svg></div>')
		}

	}
};

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = nav;
}
