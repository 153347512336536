var form = {
	container: $('form'),
	superLabelsContainer: $('.super-labels'),
	selectWrapper: $('.select-wrapper'),
	select: $('select'),
	paddingInputs: {
		newsletter: $('.form-single-item'),
		smartSearchForm: $('.smart-search-form'),
	},
	typeInputs: {
		freetext: $('input[type="text"]'),
		password: $('input[type="password"]'),
		email: $('input[type="email"]'),
		textarea: $('textarea'),
	},

	bindSuperlabels: function() {

		var forms = form.paddingInputs.newsletter.add(
			form.paddingInputs.smartSearchForm
		);

		form.superLabelsContainer.superLabels({
			labelTop: 7,
			labelLeft: 5
		});

		forms.superLabels({
			labelLeft: 11,
			labelTop: 11,
			noAnimate: true
		});

	},


	superLabelsCheck: function() {
		var $label = form.paddingInputs.smartSearchForm.find('.sl_label');
		var $input = form.paddingInputs.smartSearchForm.find('.sl_field');

		// If there is a value in the field, hide the label
		if ( $input.val() ) {
			$label.hide();
		}
	},


	ddSelect: function() {
		var countrySelect = $('#CountryOfResidence');
		//console.log('actual CountryOfResidence select element value :', countrySelect[0].value);

		if (!isTouch) {
			if (form.selectWrapper.length) {

				// temp fix so it doesn't break #map-categories dropdown
				if ( form.selectWrapper.attr('id') !== 'map-categories' ){
					form.selectWrapper.find(form.select).dropkick();
				}
				if (countrySelect.length) {
					var countrySelectDropKick = new Dropkick('#CountryOfResidence');
					countrySelect.change(function(){
						countrySelectDropKick.refresh();
						$(this).closest(form.selectWrapper).find('.dk-select').removeClass('select-hidden');
					});

					// AUTOFILL HACK (detect animation set to webkit-autofill selector)
					countrySelect[0].addEventListener("animationstart", function() {
						// console.log('autofill hack');
					    countrySelectDropKick.refresh();
					    $(this).closest(form.selectWrapper).find('.dk-select').removeClass('select-hidden');
					}, false);

					// countrySelect.allchange(function () {
					// 	console.log('autofill hack');
					//     //select.value = this[0].value;
					//     select.refresh();
					//     $(this).closest(form.selectWrapper).find('.dk-select').removeClass('select-hidden');
					// });
				}
				// form.select.addClass('select-hidden');

				if ( form.selectWrapper.attr('id') !== 'map-categories' ){
					form.select.addClass('select-hidden').attr('tabindex', -1);
				}

				// form.selectWrapper.each(function(){
				// 	var errorMsg = $(this).next('span');

				// 	$(errorMsg).bind('DOMNodeInserted', function() {
				// 		console.log('select error inserted');
				// 		$(this).prev(form.selectWrapper).find('.dk-selected').addClass('select-error');
				// 	});

				// 	$(errorMsg).bind('DOMNodeRemoved', function() {
				// 		console.log('select error removed');
				// 		$(this).prev(form.selectWrapper).find('.dk-selected').removeClass('select-error');
				// 	});
				// });

				// $('.dk-option').click(function() {
				// 	$(this).closest('.dk-selected').removeClass('select-error');
				// 	$(this).closest(form.selectWrapper).next('span').removeClass('field-validation-error').addClass('field-validation-valid').empty();
				// });
			}
		}
	},

	keyboardAmends: function() {

	},

	preventZoom: function() {
		if (isTouch) {
			var searchHeader = $('#search');
			var allInputs = searchHeader;
			allInputs.css('font-size', '16px');
		}
	},

	hiddenForm: {
		wrapper: $('.with-hidden-form'),
		openEl: $('.hidden-form-open'),
		closeEl: $('.hidden-form-close'),
		contentEl: $('.hidden-form'),

		openForm: function() {
			form.hiddenForm.openEl.click(function(event){
				event.preventDefault();
				$(this).hide().closest(form.hiddenForm.wrapper).find(form.hiddenForm.contentEl).slideDown(500, function(){
						$(this).closest(form.hiddenForm.wrapper).find(form.hiddenForm.closeEl).fadeIn();
					});
				$(this).closest('form').find('input').first().focus();
			});
		},

		closeForm: function() {
			form.hiddenForm.closeEl.click(function(event){
				event.preventDefault();
				$(this).fadeOut();
				$(this).closest(form.hiddenForm.wrapper).find(form.hiddenForm.openEl).show();
				$(this).fadeOut().closest(form.hiddenForm.wrapper).find(form.hiddenForm.contentEl).slideUp();
			});
		},

		init: function() {
			form.hiddenForm.openForm();
			form.hiddenForm.closeForm();
		}
	},

	buttonRadio: {
		wrapperEl: $('.button-radio-wrapper'),
		inputEl: $('.button-radio').find('input'),
		buttonEl: $('.button-radio').find('.button'),

		highlightOnLoad: function() {
			form.buttonRadio.inputEl.each(function() {
				if ($(this).is(':checked') === true) {
					$(this).siblings('.button').addClass('inverted');
				}
			});
		},

		radioUpdate: function() {
			form.buttonRadio.buttonEl.click(function() {
				$(this).addClass('inverted');
				$(this).siblings('input').attr('checked', true);
				$(this).closest('.button-radio').siblings('.button-radio').find('input').removeAttr('checked');
				$(this).closest('.button-radio').siblings('.button-radio').find('.button').removeClass('inverted');

				if(isiPhone > -1 || isiPad > -1 || isiPod > -1) {
					form.buttonRadio.inputEl.checkboxradio('refresh');
				}

				form.profileForm.enableFormSubmit();
			});
		},

		init: function() {
			if (form.buttonRadio.wrapperEl.length) {
				form.buttonRadio.highlightOnLoad();
				form.buttonRadio.radioUpdate();
			}
		}
	},

	tooltip: {
		wrapperEl: $('.tooltip-wrapper'),
		triggerEl: $('.tooltip-trigger'),
		contentEl: $('.tooltip-content'),

		showHide: function() {
			if (form.tooltip.wrapperEl.length) {
				$(document).click(function(event) {
					if (!$(event.target).closest('.tooltip-wrapper').length) {
						form.tooltip.contentEl.fadeOut();
					}
				});
				form.tooltip.triggerEl.click(function(event) {
					$(this).closest(form.tooltip.wrapperEl).find(form.tooltip.contentEl).fadeToggle();
					form.tooltip.triggerEl.not($(this)).closest(form.tooltip.wrapperEl).find(form.tooltip.contentEl).fadeOut();
				});
			}
		}
	},

	yourBrands: {
		wrapperEl: $('#yourBrands'),

		numberOfChecked: function() {
			var checkedItems = form.yourBrands.wrapperEl.find(':checked');
			var numChecked = checkedItems.length;
			console.log(numChecked);

			if (numChecked >= 20) {
				form.yourBrands.wrapperEl.find('input[type="checkbox"]:not(:checked)').each(function(){
					$(this).attr('disabled', true);
					$(this).closest('.checkbox-wrap').addClass('disabled');
				});
			} else if (numChecked < 20 && $('.checkbox-wrap.disabled').length) {
				form.yourBrands.wrapperEl.find('input[type="checkbox"]:not(:checked)').each(function(){
					$(this).attr('disabled', false);
					$(this).closest('.checkbox-wrap').removeClass('disabled');
				});
			}
		},

		disableCheckboxes: function() {
			// Treat each checkbox separately
			form.yourBrands.wrapperEl.find('input[type="checkbox"]').each(function(){
				// Watch checkbox changes
				$(this).on('change', function() {
					form.yourBrands.numberOfChecked();
				});
			});
		},

		init: function() {
			if (form.yourBrands.wrapperEl.length) {
				form.yourBrands.disableCheckboxes();
				form.yourBrands.numberOfChecked();
			}
		}
	},

	// Force to uncheck on click when it comes checked by default from umbraco
	checkedCheckbox: {
		checkbox: $('.checkbox-wrap'),

		forceUncheck: function() {
			form.checkedCheckbox.checkbox.click(function(){
				$(this).find('[checked="checked"]').removeAttr('checked').prop('checked', true);

				setTimeout(function(){
					$(this).find('[checked="checked"]').prop('checked', false);
				},500);
			});
		}
	},

	profileForm: {
		form: undefined,
		formSubmitBtn: undefined,
		init: function() {
			// get form profile btn
			this.formSubmitBtn = $('#profileFormSubmit')[0];

			if (this.formSubmitBtn){

				// only enable submit when inputs have changed

				this.form = $(this.formSubmitBtn).closest('form');

				console.log('window.getAndroidVersion: ', window.getAndroidVersion());
				console.log('window.getAndroidVersion parsed: ', parseInt(getAndroidVersion(), 10));

				this.formSubmitBtn.setAttribute("disabled", "disabled");

				$(this.form).delegate(':input', 'change', function(e){
					console.log('delegate change event on form input: ', e);
					if (e.target.name === "__RequestVerificationToken") { return; }
					
					if (e.originalEvent.isTrusted){
						form.profileForm.enableFormSubmit();
						$(this.form).undelegate('change');
					}
				});

				$(this.form).delegate(':input', 'keyup', function(e){
					console.log('delegate keyup event on form input: ', e);
					if (e.target.name === "__RequestVerificationToken") { return; }
					
					if (e.originalEvent.isTrusted){
						form.profileForm.enableFormSubmit();
						$(this.form).undelegate('keyup');
					}
				});

				$(this.form).delegate(':input', 'keydown', function(e){
					console.log('delegate keydown event on form input: ', e);
					if (e.target.name === "__RequestVerificationToken") { return; }
					
					if (e.originalEvent.isTrusted){
						form.profileForm.enableFormSubmit();
						$(this.form).undelegate('keydown');
					}
				});

				// doesn't work in samsung internet browser?
			    $('.select-hidden').one('change', function(e){
			    	console.log("$('.select-hidden') one change, e: ", e);
			    	form.profileForm.enableFormSubmit();
			    });

			    // TEST
			    $('.select-hidden').on('change', function(e){
			    	console.log("$('.select-hidden') on change, e: ", e);
			    	form.profileForm.enableFormSubmit();
			    });

			    // TEST
			    $('form :input').one('change keyup', function(e){
			    	console.log('$("form :input") on change keyup, e: ', e);

			    	// why does it fire a change (and keyup) event on CurrentPassword and ConfirmNewEmail on page load?
			    	if (e.originalEvent.isTrusted){
			    		form.profileForm.enableFormSubmit();
			    	}
			    });
			}
		},
		enableFormSubmit: function(){
			console.log('enableFormSubmit');
			if (this.formSubmitBtn){
				this.formSubmitBtn.removeAttribute("disabled");
			}
		}
	},

	init: function() {
		form.bindSuperlabels();
		form.ddSelect();
		form.hiddenForm.init();
		form.buttonRadio.init();
		form.tooltip.showHide();
		form.yourBrands.init();
		form.checkedCheckbox.forceUncheck();

		form.superLabelsCheck();

		// enabling/disabling submit functionality
		// form.profileForm.init();

		if (windowWidth >= desktop) {
			form.keyboardAmends();
		}
	}
};

// $.fn.allchange = function (callback) {
// 	var me = this;
// 	var last = '';
// 	var infunc = function () {
// 		var text = $(me).val();
// 		if (text !== last) {
// 			last = text;
// 			callback();
// 			// callback.apply(me);
// 		}
// 		setTimeout(infunc, 100);
// 	};
// 	setTimeout(infunc, 100);
// };

if (typeof exports === 'object' && typeof module === 'object') {
	module.exports = form;
}
